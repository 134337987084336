<template>
  <div>
     <v-progress-circular class="loader" size='70' color="black" v-if="loader" indeterminate ></v-progress-circular>
    <v-row class="ma-0 pa-4 align-center">
      <v-col cols="2" class="pa-0"
        ><h3 width="100" height="24" class=".text-lg-h6">Customer</h3></v-col
      >
      <v-col class="pa-0" cols="1"></v-col>
      <v-col cols="6" class="pa-0">
        <v-text-field
          v-model="search"
          single-line
          dense
          outlined
          prepend-inner-icon="mdi-magnify"
          placeholder="Search"
        ></v-text-field>
      </v-col>
      <v-col class="pa-0" cols="1"></v-col>

      <v-col cols="2" class="pa-0 d-flex justify-end"
        ><v-btn
          depressed
          color="align-center justify-center  text-none pa-0 bgr_clr_btn_save "
          min-width="155px"
          @click="nextpage()"
        >
          Create Customer
        </v-btn></v-col
      >
    </v-row>

  
     
     <v-data-table 
     
        :headers="headers"
        v-bind:items="this.customerList"
        :search="search"
        :value="this.customerList"
        item-key="name"
        class="elevation-1  mx-5"
        id="table"
      >
       <template slot="this.customerList" slot-scope="">
          <tr>
            <th
              v-for="(item, index) in this.customerList"
               :key="index"
            class="tableRowHeight"
           

            ></th>
          </tr>
        </template>
        <template v-slot:item="props">
          <tr  @click="createCustomer(props.index)">
            
            <td>{{ props.index + 1 }}</td>
            <td>{{ props.item.customer_name }}</td>
            <td>{{ props.item.aadhar_no }}</td>
            <td>{{ props.item.mobile_number }}</td>
            <td>{{ props.item.Credits }}</td>
            <td>{{ props.item.address }}</td>
            <td>{{ props.item.customer_type }}</td>
            <td>{{ props.item.pan_card }}</td>
            <td class="pa-0">  <span class="pa-0"   v-bind:class="{
                    inprogress: props.item.status == 'In-Progress',
                    success: props.item.status == 'Completed',
                  }"></span>
              <!-- <img :src="props.item.status == 'Completed'? completed : inprogress" />  -->
            <span class="ml-2 pa-0">
             {{ props.item.status }} 
            </span>
              </td>
          </tr>
        </template>
      </v-data-table>
  </div>
</template>

 <script>
import httpService from "../js/httpservice"
import EventBus from "../main.js";
import Vue from "vue"
import completed from "../assets/completed.svg";
import inprogress from "../assets/progress.svg";
import {mapState} from 'vuex';
export default {
  data() {
    return {
      search: "",
     
    
      // page: 1,
      name: "",
      loader: false,
      userid: "",
      i: "",
      customerType: "",
      searchQuery: null,
      customerList: [],
       headers: [
        {
          text: "S.NO",
          align: "start",
          sortable: false,
          value: "customer_name",
          class: "",
        },
        { text: "Name", value: "customer_name ", sortable: false },
        { text: "AADHAR Card", value: "aadhar_no", sortable: false },
        { text: "Mobile Number", value: "mobile_number", sortable: false },
        { text: "Credits", value: "Credits", sortable: false },
        { text: "Area", value: "address", sortable: false },
        { text: "Customer Type", value: "customer_type", sortable: false },
        { text: "PAN CARD", value: "pan_card", sortable: false },
        { text: "Status", value: "", sortable: false },
        
      ],
    };
  },
  computed:{
       ...mapState(['loading'])
    },
  methods: {
    nextpage() {
      this.$router.push("/createcustomer");
    },
    getCustomerDetails() {
      this.loader = true
      let jsondata = {
        branch_id: "1",
      };
      httpService.getcustomerAllDetail(jsondata).then(
        // loading = true,
        (response) => {
          if (response.status == 200) {
            if (response.data["status"] == 1) {
              this.customerList = response.data.result;
              var allgetcustomerDetail = response.data.result;
              for (let item of allgetcustomerDetail) {
                this.userid = item.user_id;
                if (item.user_id == this.userid) {
                  this.customerType = item.customer_type;
                  if (item.customer_type == this.customerType) {
                    this.customerType == null;
                  }
                  // customer_type
                  // bank_ifsc_code
                }
              }

              // item.have_nominee == "No"
              // console.log(allgetcustomerDetail);
              // for (let item of allgetcustomerDetail) {

              //   this.name = item.customer_name
              //   this.panCard = item.pan_card
              // this.whatsappNo =  item.whatsapp_no
              //   this.aadharCard =  item.aadhar_no
              //     this.mobileNo =  item.mobile_number
              //       this.married =  item.marital_status
              //             this.customerType =  item.customer_type
              //   this.Area =  item.address
              //    console.log(this.name);

              // }
            } else {
              //  this.progressBar = false
              // this.openNotification({
              //   type: "danger",
              //   message: response.data["reason"],
              //   duration: 3000,
              // });
            }
          }
        },
        (err) => {
          console.log(err)
       
        }).finally(() => { this.loader = false });
    },
    createCustomer(val) {
      console.log(this.customerList[val]);
      this.$router.push("/profilecustomer");
      EventBus.$emit("customerDetail", this.customerList[val]);
      Vue.prototype.$message = this.customerList[val]
      this.$store.state.message = this.customerList[val]
      //  this.$router.push("/profilecustomer",this.customerList[val]);
    },
  },
  

  mounted() {
  
    if (
      localStorage.getItem("userprofile") != undefined ||
      localStorage.getItem("userprofile") != null
    ) {
      this.userId = JSON.parse(localStorage.getItem("userprofile"))["user_id"];
      console.log(this.userId);
    }
    // this.getPersonalDetails();
    this.getCustomerDetails();
  },
};
</script> 

<style scoped>
.tableRowHeight {
  height: 56px !important;
}
::v-deep .v-text-field__details {
  display: none !important;
}
::v-deep .v-input__slot {
  margin: 0 !important;
}
.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  font-size: inherit;
}
.v-btn:not(.v-btn--round).v-size--default {
  height: 40px;
}
.container {
  max-width: 1366px;
}
.fontfamily {
  font-family: "Lato";
}
.clr_orange {
  /* background-color: #000 !important; */
  /* color: #f25408; */
  background-color: #ec5f1e !important;
}
.completed{
  color: red;
  border-radius:8px;
}
.v-data-table ::v-deep th{
        font-size: 14px !important;
         color: black !important;
}
.inprogress {
  height: 12px;
  width: 12px;
  background-color: #ff9125 !important;
  vertical-align: middle;
  border-radius: 50%;
  display: inline-block;
}
.success{
  height: 12px;
  width: 12px;
  background-color: #1F991F !important;
  vertical-align: middle;
  border-radius: 50%;
  display: inline-block;
}
  ::v-deep .v-data-table__empty-wrapper {
        display: none !important;
    }
</style>